//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import icon from './index';
export default {
  mixins: [icon],
  props: {
    name: String,
    align: {
      type: String,
      default: 'tw-align-middle'
    },
    display: {
      type: String,
      default: 'inline-block'
    },
    color: {
      type: String,
      default: 'fill-current'
    },
    width: {
      type: String,
      default: 'tw-w-4'
    },
    height: {
      type: String,
      default: 'tw-h-4'
    },
    viewbox: {
      type: String,
      default: '0 0 25 25'
    },
    fill: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary',

      validator(x) {
        return ['primary', 'secondary', 'warning', 'dark', 'light', 'info', 'danger', 'success', 'tertiary', 'sidebar', ''].includes(x);
      }

    }
  },
  methods: {
    clickHandler(event) {
      this.$emit('click', event);
    }

  }
};